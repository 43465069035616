import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Player from './pages/Player'
import Explore from './pages/Explore'
import Subscription from './pages/Subscription'
import Signin from './pages/Auth/Login'
import Signup from './pages/Auth/Register'
import OTP from './pages/Auth/OTP'
import ResetPassword from './pages/Auth/PasswordReset'
import ForgotPassword from './pages/Auth/ForgotPassword'
import ResetOTP from './pages/Auth/VerifyResetOTP'
import PremiumSubscription from './pages/Subscription/Plan'
import Payment from './pages/Subscription/Payment'
import ProtectedeRoute from './component/ProtectedRoute'
import ExploreCategory from './pages/Explore/Category'
import ExplorePlayer from './pages/Explore/Player'
import Library from './pages/Library'
import Profile from './pages/Profile'
import Promos from './pages/Promos'
import Coins from './pages/Coin'
import CoinsBalance from './pages/Coin/Balance'
import CoinPayment from './pages/Coin/Payment'
import PreviewPage from './pages/Preview'


const RouterConfig = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/explore' element={<Explore />} />
                {/* <Route exact path='/explore' element={<Explore />} /> */}
                <Route exact path='/explore/category/:slug' element={<ExploreCategory />} />
                <Route exact path='/library' element={<Library />} />

                {/* <Route exact path='/' element={<ExplorePlayer />} /> */}
                <Route path='/title/:id' element={<PreviewPage />} />


                {/* <Route exact path='/subscription' element={<Subscription />} />
                <Route exact path="/subscriptions" element={<Subscription />} /> */}

                <Route exact element={<ProtectedeRoute />}>
                    {/* <Route exact path='/subscription/plans' element={<PremiumSubscription />} />
                    <Route exact path='/subscription/payment' element={<Payment />} /> */}
                    <Route exact path='/' element={<Coins />} />
                    <Route exact path='/title/:slug' element={<ExplorePlayer />} />
                    <Route exact path='/profile' element={<Profile />} />
                    <Route exact path='/coin' element={<Coins />} />
                    <Route exact path='/coin/balance' element={<CoinsBalance />} />
                    <Route exact path='/coin/payment' element={<CoinPayment />} />
                </Route>


                <Route exact path='/promos-and-referral/:token' element={<Promos />} />


                <Route exact path='/auth/register' element={<Signup />} />
                <Route exact path='/auth/login' element={<Signin />} />
                <Route exact path='/auth/OTP' element={<OTP />} />
                <Route exact path='/auth/verify-OTP' element={<ResetOTP />} />
                <Route exact path='/auth/reset-password' element={<ResetPassword />} />
                <Route exact path='/auth/forgot-password' element={<ForgotPassword />} />
            </Routes>
        </BrowserRouter>
    )
}
export default RouterConfig