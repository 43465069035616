const ENDPOINTS = {
    LOGIN: '/auth/email-login',
    REGISTER: '/auth/create-user',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
    FACEBOOK_LOGIN:'auth/login-with-facebook-web',
    GOOGLE_LOGIN:'auth/login-with-google-web',
    REQUEST_OTP: '/auth/send-email-otp',
    VERIFY_OTP: '/auth/verify-otp',
    LOGOUT: '/auth/logout',
    DASHBOARD_DETAILS: '/get-dashboard-details',
    TOP_RELEASES: '/get-top-releases',
    RECENT_TRANSACTIONS: '/get-recent-transactions',
    CONTENT: '/get-content',
    GENRE_LIST: '/get-genre?all=1',
    SUB_GENRE_LIST: '/get-sub-genre?all=1',
    LANGUAGE_LIST: '/get-language?all=1',
    TYPE_LIST: '/get-types?all=1',
    UPLOAD_RSS: '/upload-rss',
    UPLOAD_METADATA: '/upload-title-metadata',
    RSS_EPISODES: '/get-episodes-from-rss',
    SUBSCRIPTION: '/get-plans-web',
    VERIFY: '/paystack/verify',
    STRIPE_VERIFY: '/stripe/verify',
    USER_PLANS: '/subscription/status',
    CUSTOMER_ID: '/stripe/get-customer-id',
    HOME_RANKINGS: '/get-home-rankings-na',
    HOME_DASHBOARD: '/get-home-dashboard-na',
    TRENDING_TITLES: '/get-trending-titles-dashboard-na',
    TOP_TITLES: '/get-top-titles',
    AUTO_SEARCH: '/autocomplete-title-search',
    PREMIUM_FEATURES:'/get-premium-plan',
    TRIAL_DAYS:'/get-highest-trial-days',
    CANCEL_SUBSCRIPTION:"/cancel-subscription",
    VERIFY_REFERRAL_CODE:"/log-referral-usage",
    STORE_DEVICE_SIGNATURE:"/store-device-signature",
    GET_SHARE_DETAILS: "/guest/get-share-details"

};

export default ENDPOINTS;