import { RiFacebookLine, RiLinkedinLine, RiTwitterLine } from 'react-icons/ri'
import { BsInstagram } from 'react-icons/bs'

export default function PreviewFooter({ handleAppRedirect }){
    return (
        <footer className="preview-footer">
            <div className="footer-content">
                <div className="footer-main">
                    <div className="footer-brand">
                        <img src="/logo.svg" style={{width: '100px'}} alt="Genti Logo" />
                        <p>
                            African Audio Stories, Audiobooks, Podcasts and more. 
                            Mobile App. Download the app and listen for free!
                        </p>
                        <div className="footer-store-buttons">
                            <img onClick={() => {
                                handleAppRedirect('ios')
                            }} src="/app-store.svg" alt="App Store" />
                            <img onClick={() => {
                                handleAppRedirect('android')
                            }} src="/google-play.svg" alt="Google Play" />
                        </div>
                    </div>

                    <div className="footer-links-group">
                        <h3>Explore</h3>
                        <ul>
                            <li><a href="#">Contact Us</a></li>
                            <li><a href="#">Subscriptions</a></li>
                            <li><a href="#">FAQs</a></li>
                            <li><a href="#">Terms & Conditions</a></li>
                        </ul>
                    </div>

                    <div className="footer-links-group">
                        <h3>For Storytellers</h3>
                        <ul>
                            <li><a href="#">Writers</a></li>
                            <li><a href="#">Voice Artists</a></li>
                            <li><a href="#">Creatives</a></li>
                        </ul>
                    </div>

                    <div className="footer-links-group">
                        <h3>Need Help?</h3>
                        <ul>
                            <li><a href="#">FAQs</a></li>
                            <li><a href="#">Contact Us</a></li>
                            <li><a href="#">Terms & Conditions</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                        </ul>
                    </div>

                    <div className="footer-social">
                        <h3>Follow us</h3>
                        <div className="social-icons">
                            <a href="#" className="social-icon">
                                <RiFacebookLine />
                            </a>
                            <a href="#" className="social-icon">
                                <RiLinkedinLine />
                            </a>
                            <a href="#" className="social-icon">
                                <RiTwitterLine />
                            </a>
                            <a href="#" className="social-icon">
                                <BsInstagram />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>Genti Media 2025. All rights reserved</p>
            </div>
        </footer>
    )
}