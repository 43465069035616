import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import Client from "../client";

const PreviewHelmet = () => {

    const params = useParams()
    const { id } = params

    const {
        data: guestTitle, isLoading: fetchIsLoading, isFetching, isError
    } = useQuery('get-share-details', async () => {
        const response = await Client.preview.get_share_details(id)
        const data = response?.data
        return data
    })

    return (
        <Helmet>
            <title>{guestTitle?.title}</title>
            <meta name="description" content={guestTitle?.description} />

            <meta property="og:description" content={guestTitle?.description} />
            <meta property="og:title" content={guestTitle?.title}/>
            <meta property="og:image" content={guestTitle?.image} />
            <meta property="og:url" content={`https://player.gentimedia.com/title/${id}`} />
            
            <meta name="twitter:image" content={guestTitle?.image}/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={guestTitle?.title}/>
            <meta name="twitter:description" content={guestTitle?.description}/>
        </Helmet>
    )

}

const GeneralHelmet = () => {
    <Helmet>
        <title>Genti is an audio media platform dedicated to showcasing African audio stories, dramas, educational materials and more in African languages and voices! Check these out on the Genti Audio</title>
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/index.png" />
        <meta name="description" content="Genti Media" />
        <title>Genti | Media</title>
    </Helmet>
}

export const SEO = () => {

    const location = useLocation();
    const pathname = location.pathname;

    return pathname.startsWith('/title') ? PreviewHelmet : GeneralHelmet
}